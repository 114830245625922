import React from 'react';
import Main from '../containers/Layout';
import CommercialInvoicesContent from '../../content/pages/commercial-invoices-for-export.yml';
import CommercialInvoicesContainer from '@/containers/toolkit/CommercialInvoicesForExportContainer';

export interface CommercialInvoicesPageContent {
  viewport1: {
    title1: string;
    title2: string;
    description: string;
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    title: string;
    carouselItems: {
      title: string;
      description: string;
    }[];
  };
  viewport3: {
    cards: {
      title: string;
      tickedDescription: { item: string }[];
      image: string;
    }[];
  };
  viewport4: {
    label: string;
    button: {
      label: string;
      url: string;
    };
  };
}

const CommercialInvoicesForExport: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3, viewport4 } =
    CommercialInvoicesContent as unknown as CommercialInvoicesPageContent;
  return (
    <Main>
      <CommercialInvoicesContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
        }}
      />
    </Main>
  );
};

export default CommercialInvoicesForExport;
